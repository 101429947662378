
document.addEventListener('DOMContentLoaded', (_) => {
  const modal = document.getElementById("modal");
  window.onclick = function (event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  }
  const form = document.getElementById("closed-alpha");
  const url = "https://hq3feh7jgd.execute-api.eu-west-2.amazonaws.com/prod/api/register";
  form.addEventListener("submit", async (e) => {
    e.preventDefault();
    const submitBtn = e.target["submit"] as HTMLButtonElement;
    submitBtn.textContent = "Loading..."
    const email = e.target["email"]?.value;
    const name = e.target["name"]?.value;

    if (!name || !email) {
      alert("Please enter a valid name and email combination");
    }

    try {
      const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email })
      });
      const response = await rawResponse.json();
      modal.style.display = "flex";
      //@ts-ignore
      // gtag('send', {
      //   hitType: 'event',
      //   eventCategory: 'RegisterForm',
      //   eventAction: 'submit',
      //   eventLabel: 'Alpha Release Campaign'
      // });
    } catch (err) {
      const errELt = document.getElementById("error");
      if (errELt) {
        console.log(err);
        errELt.style.display = "block";
        errELt.style.opacity = "1";
      }
    }
    submitBtn.textContent = "Submit";
    (e.target as HTMLFormElement).reset();
  });

  document.getElementById("cookieConsent").style.opacity = "1";
  document.querySelector("#closeCookieConsent").addEventListener("click", () => {
    document.getElementById("cookieConsent").style.opacity = "0";
    document.getElementById("cookieConsent").style.zIndex = "-1000";
  });
});